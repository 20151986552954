import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/notifications').reply(200, {
  notifications: [
    {
      id: '5e8883f1b51cc1956a5a1ec0',
      title: 'Your claim has been denied.',
      description: 'Issue was not caused by fault of supplier',
      type: 'order_placed',
      createdAt: moment()
        .subtract(2, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: '5e8883f7ed1486d665d8be1e',
      title: 'More information required',
      description: 'Claim 1234-B requires more information.',
      type: 'new_message',
      createdAt: moment()
        .subtract(1, 'day')
        .toDate()
        .getTime()
    },
    {
      id: '5e8883fca0e8612044248ecf',
      title: 'Claim paid',
      description: 'Your claim has been approved. The check is in the mail.',
      type: 'item_shipped',
      createdAt: moment()
        .subtract(3, 'days')
        .toDate()
        .getTime()
    }
  ]
});
