/* eslint-disable no-param-reassign */
import produce from 'immer';
import findIndex from 'lodash/findIndex';
import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATIONS_AS_READ
} from 'src/actions/notificationsActions';

const initialState = {
  notifications: []
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return produce(state, (draft) => {
        draft.notifications = notifications;
      });
    }
    case MARK_NOTIFICATIONS_AS_READ: {
      const { notifications } = action.payload;

      return produce(state, (draft) => {
        draft.notifications = notifications;
      });
    }

    case MARK_NOTIFICATION_AS_READ: {
      const { notification } = action.payload;

      return produce(state, (draft) => {
        let i = findIndex(draft.notifications, { id: notification.id });
        draft.notifications[i] = notification;
      });
    }

    case UPDATE_NOTIFICATIONS: {
      const { notification } = action.payload;

      return produce(state, (draft) => {
        draft.notifications.push(notification);
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationsReducer;
