/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ADD_CLAIM,
  UPDATE_CLAIM,
  GET_CLAIMS,
  SET_CLAIM_ID
} from 'src/actions/claimActions';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { GET_NOTIFICATIONS } from '../actions/notificationsActions';

const initialState = {
  claims: [],
  claimId: 0
};

const claimsReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_CLAIMS: {
      const { claims } = action.payload;
      return produce(state, (draft) => {
        draft.claims = claims;
      });
    }

    case UPDATE_CLAIM: {
      const { claim } = action.payload;
      return produce(state, (draft) => {
        let i = findIndex(draft.claims, { id: claim.id });
        draft.claims[i] = claim;
      });
    }

    case ADD_CLAIM: {
      const { claim } = action.payload;
      return produce(state, (draft) => {
        if (!find(draft.claims, { id: claim.id })) {
          draft.claims.push(claim);
        }
      });
    }
    case SET_CLAIM_ID: {
      const  claimId  = action.payload;
      console.log("In data store", claimId);
      return produce(state, (draft) => {
        draft.claimId = claimId;
      })
    }

    default: {
      return state;
    }
  }
};

export default claimsReducer;
