import axios from 'axios';
import jwtDecode from 'jwt-decode';

const api = process.env.REACT_APP_RETAIL_CLAIMS_PORTAL || 'https://api.nationsafedrivers.com/nsd-api-consumer-portal/v1';

class ClaimService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  updateClaim = (claim) => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    // axios.put('http://localhost:7071/api/claims/all', claim, {
    axios.put(`${api}/claims/all`, claim, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  getClaims = () => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    // axios.get('http://localhost:7071/api/claims/all', {
    axios.get(`${api}/claims/all`, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  getClaim = (id) => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    // axios.get(`http://localhost:7071/api/claims/${id}`, {
    axios.get(`${api}/claims/${id}`, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  makeClaim = (data) => new Promise((resolve, reject) => {
    try {
      if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
        this.setSession(localStorage.getItem('accessToken'));
      }
      // axios.post(`http://localhost:7071/api/claims`, data, {
      axios.post(`${api}/claims`, data, {
        headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
      })
        .then((response) => {
          this.setSession(response.data.accessToken);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      reject(e);
    }
  });

}

const claimService = new ClaimService();
claimService.setSession(claimService.getAccessToken());

export default claimService;
