import axios from 'axios';
import jwtDecode from 'jwt-decode';

const api = process.env.REACT_APP_RETAIL_CLAIMS_PORTAL || 'https://api.nationsafedrivers.com/nsd-api-consumer-portal/v1';

class NotificationsService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  getNotifications = () => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    const token = jwtDecode(this.getAccessToken());
    // axios.get(`http://localhost:7071/api/notifications/all?userId=${token.id}&unreadOnly=true`, {
    axios.get(`${api}/notifications/all?userId=${token.id}&unreadOnly=true`, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  markNotificationAsRead = (notification) => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    const token = jwtDecode(this.getAccessToken());
    axios.put(`${api}/notifications/d`, {
      // axios.put(`http://localhost:7071/api/notifications`, {
      claimEvent: {
        ...notification,
        isReviewedByCustomer: true
      },
      user: token
    }, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  markAllNotificationsAsRead = () => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    const token = jwtDecode(this.getAccessToken());
    axios.put(`${api}/notifications/all`, {
      // axios.put(`http://localhost:7071/api/notifications/all`, {
      user: token
    }, {
      headers: { 'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef' }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  updateNotifications = (notification) => new Promise((resolve, reject) => {
    resolve(notification);
  });
}

const notificationsService = new NotificationsService();
notificationsService.setSession(notificationsService.getAccessToken());

export default notificationsService;
