import axios from 'src/utils/axios';
import authServiceMock from 'src/services/authServiceMock';
import authService from 'src/services/authService';
import notificationsService from '../services/notificationsService';
import { GET_NOTIFICATIONS } from './notificationsActions';
import claimService from '../services/claimService';

export const ADD_CLAIM = '@account/add-claim';
export const UPDATE_CLAIM = '@account/update-claim';
export const GET_CLAIMS = '@account/get-claim';
export const SET_CLAIM_ID = '@account/set-claim-id';
export function addClaim(claim) {
  const request = claimService.makeClaim(claim);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: ADD_CLAIM,
      payload: response.data
    }));
  };
}

export function getClaims() {
  const request = claimService.getClaims();

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_CLAIMS,
      payload: response.data
    }));
  };
}
export function setClaimId(claimId){
  
  console.log('In dispatch', claimId);
  return (dispatch) => {
     dispatch({
      type: SET_CLAIM_ID,
      payload: claimId
    });
  };
}

export function saveClaim(claim) {
  const request = claimService.updateClaim(claim);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_CLAIM,
      payload: response.data
    }));
  };
}
