import axios from 'axios';
import jwtDecode from 'jwt-decode';

const api = process.env.REACT_APP_RETAIL_CLAIMS_PORTAL || 'https://api.nationsafedrivers.com/nsd-api-consumer-portal/v1';

class AccountService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  updateProfile = (update) => new Promise((resolve, reject) => {
    if (!this.getAccessToken() && localStorage.getItem('accessToken')) {
      this.setSession(localStorage.getItem('accessToken'));
    }
    axios.post(`${api}/account/me`, { user: update }, {
      headers: {
        'Ocp-Apim-Subscription-Key': 'f005ba91859a4e2fba39f2432acea1ef',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
      .then((response) => {
        this.setSession(response.data.accessToken);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

const accountService = new AccountService();
accountService.setSession(accountService.getAccessToken());

export default accountService;
