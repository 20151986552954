import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles, Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="overline"
                color="secondary"
              >
                Introducing
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
              >
                Tire Rack Claims Portal
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Welcome to the future!
                  <br/>
                  <br/>
                  Our new digital road hazard claim portal has been developed to provide our valued customers with a
                  best-in-class experience. This self-serve portal was designed to maximize the speed and security with
                  which your claim will be resolved. It is available 24/7/365 from any place and on any device! Just
                  register, log in, and follow the easy instructions. Automated alerts will keep you informed of the
                  claim status every step of the way.
                </Typography>
              </Box>
              {/*<Box mt={3}>*/}
              {/*  <Grid*/}
              {/*    container*/}
              {/*    spacing={3}*/}
              {/*  >*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        variant="h1"*/}
              {/*        color="secondary"*/}
              {/*      >*/}
              {/*        3,000+*/}
              {/*      </Typography>*/}
              {/*      <Typography*/}
              {/*        variant="overline"*/}
              {/*        color="textSecondary"*/}
              {/*      >*/}
              {/*        Customers*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        variant="h1"*/}
              {/*        color="secondary"*/}
              {/*      >*/}
              {/*        Automated*/}
              {/*      </Typography>*/}
              {/*      <Typography*/}
              {/*        variant="overline"*/}
              {/*        color="textSecondary"*/}
              {/*      >*/}
              {/*        Claims Handling*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        variant="h1"*/}
              {/*        color="secondary"*/}
              {/*      >*/}
              {/*        300+*/}
              {/*      </Typography>*/}
              {/*      <Typography*/}
              {/*        variant="overline"*/}
              {/*        color="textSecondary"*/}
              {/*      >*/}
              {/*        Support Reps*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*</Box>*/}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/claims.jpeg"
                />
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h1"
              color="textSecondary"
            >
              Ready to get started with managing your claims?
            </Typography>

            <Typography
              variant="h3"
              color="secondary"
            >
              Already registered
            </Typography>
            <br/>
            <Button
              color="secondary"
              component="a"
              href="/login"
              variant="contained"
            >
              Log in
            </Button>
            <br/>
            <br/>
            <Typography
              variant="h3"
              color="secondary"
            >
              Not registered yet? Click register to get started.
            </Typography>
            <br/>
            <Button
              color="secondary"
              component="a"
              href="/register"
              variant="contained"
            >
              Register
            </Button>

            {/*<Typography*/}
            {/*  variant="body1"*/}
            {/*  color="textSecondary"*/}
            {/*>*/}
            {/*  <Link to={'/login'}>Login</Link> in or <Link to={'/register'}>register</Link> to file a claim.*/}
            {/*</Typography>*/}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
