import _ from 'lodash';
import jwt from 'jsonwebtoken';
import mock from 'src/utils/mock';

const JWT_SECRET = 'devias-top-secret-key';
const JWT_EXPIRES_IN = '2 days';

const db = {
  user: {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/images/avatars/avatar_6.png',
    bio: 'Sales Manager',
    canHire: false,
    country: 'USA',
    email: 'katarina.smith@devias.io',
    username: 'admin',
    password: 'admin',
    firstName: 'Katarina',
    isPublic: true,
    lastName: 'Smith',
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York',
    timezone: '4:32PM (GMT-4)'
  }
};

const vehiclesDB = [
  {
    id: 1,
    customerId: 1,
    vehicleMake: 'Toyota',
    vehicleModel: 'Tacoma 2wd Prerunner',
    vehicleYear: 2012
  },
  {
    id: 2,
    customerId: 2,
    vehicleMake: 'Mercedes-Benz',
    vehicleModel: 'GLE43 AMG Coupe',
    vehicleYear: 2017
  },
  {
    id: 3,
    customerId: 3,
    vehicleMake: 'Honda',
    vehicleModel: 'Accord Sedan',
    vehicleYear: 2015
  }
];

const customersDB = [
  {
    id: 1,
    firstName: 'Rajiv',
    lastName: 'Amar',
    address1: '10701 SUNRISE CIR',
    address2: 'Apt 649',
    city: 'ARGYLE',
    state: 'TX',
    country: 'US',
    zipCode: 76226,
    zipCode2: 7704,
    email: 'ramar@nationsafedrivers.com',
    phone: 8587755194,
    shipToPhone: 8587755194,
    avatar: '/static/images/avatars/avatar_1.png',
    bio: 'Mr.',
    canHire: false,
    username: 'admin',
    password: 'admin',
    isPublic: true,
    role: 'customer'
  },
  {
    id: 2,
    firstName: 'Trevor',
    lastName: 'Moore',
    address1: '12363 AMETHYST CIR',
    address2: '',
    city: 'CARMEL',
    state: 'IN',
    country: 'US',
    zipCode: 97266,
    zipCode2: null,
    email: 'trevor.j.moore@gmail.com',
    phone: 7072921444,
    shipToPhone: 7072921444,
    // avatar: '/static/images/avatars/avatar_4.png',
    bio: 'Mr.',
    canHire: false,
    username: 'admin',
    password: 'admin',
    isPublic: true,
    role: 'customer'
  },
  {
    id: 3,
    firstName: 'JUSTIN C',
    lastName: 'MCKEEVER',
    address1: '840 SE DEERFIELD LN',
    address2: '',
    city: 'WAUKEE',
    state: 'IA',
    country: 'US',
    zipCode: 50263,
    zipCode2: 8605,
    email: 'JCMCKEEVER@LIVE.COM',
    phone: 5157100176,
    shipToPhone: 5157100176,
    avatar: '/static/images/avatars/avatar_7.png',
    bio: 'Mr.',
    canHire: false,
    username: 'admin',
    password: 'admin',
    isPublic: true,
    role: 'customer'
  }
];

const invoicesDB = [
  {
    id: 1,
    customerId: 1,
    invoice: 'EX44163',
    invoiceDate: '8/3/20',
    orderDate: '7/18/20',
    tireTotal: 639.96,
    firstName: 'Rajiv',
    lastName: 'Amar',
    address1: '10701 SUNRISE CIR',
    address2: '',
    city: 'ARGYLE',
    state: 'TX',
    country: 'US',
    zipCode: 76226,
    zipCode2: 7704,
    email: 'ramar@nationsafedrivers.com',
    phone: 8178258242,
    vehicleMake: 'Toyota',
    vehicleModel: 'Tacoma 2wd Prerunner',
    vehicleYear: 2012,
    invoiceLines: [
      {
        id: 1,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019501,
        dotNumber: 'UTV61M64119'
      },
      {
        id: 2,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019502,
        dotNumber: 'UTV61M64119'
      },
      {
        id: 3,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019503,
        dotNumber: 'UTV61M64119'
      },
      {
        id: 4,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019504,
        dotNumber: 'UTV61M64119'
      }
    ]

  },
  {
    id: 2,
    customerId: 2,
    invoice: 'EY44447',
    invoiceDate: '8/3/20',
    orderDate: '7/31/20',
    tireTotal: 1447.04,
    firstName: 'Trevor',
    lastName: 'Moore',
    address1: '12363 AMETHYST CIR',
    address2: '',
    city: 'CARMEL',
    state: 'IN',
    country: 'US',
    zipCode: 46032,
    zipCode2: 9758,
    email: 'trevor.j.moore@gmail.com',
    phone: 3177419012,
    vehicleMake: 'Mercedes-Benz',
    vehicleModel: 'GLE43 AMG Coupe',
    vehicleYear: 2017,
    invoiceLines: [
      {
        id: 5,
        invoiceId: 2,
        customerPrice: 362.79,
        tireRackPrice: 351.37,
        tireRackItem: '84YR2SPC5',
        vendorItem: 3564830000,
        quantity: 1,
        sectionWidth: 285,
        aspectRatio: 40,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2094,
        loadRange: 'SL',
        speedRating: '(106Y)',
        roadHazardId: 440000019601,
        dotNumber: 'HWTPD62R2119'
      },
      {
        id: 6,
        invoiceId: 2,
        customerPrice: 362.79,
        tireRackPrice: 351.37,
        tireRackItem: '84YR2SPC5',
        vendorItem: 3564830000,
        quantity: 1,
        sectionWidth: 285,
        aspectRatio: 40,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2094,
        loadRange: 'SL',
        speedRating: '(106Y)',
        roadHazardId: 440000019602,
        dotNumber: 'HWTPD62R2119'
      },
      {
        id: 7,
        invoiceId: 2,
        customerPrice: 360.73,
        tireRackPrice: 349.38,
        tireRackItem: '235YR2SPC5',
        vendorItem: 3564820000,
        quantity: 1,
        sectionWidth: 325,
        aspectRatio: 35,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2337,
        loadRange: 'SL',
        speedRating: '(110Y)',
        roadHazardId: 440000019603,
        dotNumber: 'HWUWD62P1720'
      },
      {
        id: 8,
        invoiceId: 2,
        customerPrice: 360.73,
        tireRackPrice: 349.38,
        tireRackItem: '235YR2SPC5',
        vendorItem: 3564820000,
        quantity: 1,
        sectionWidth: 325,
        aspectRatio: 35,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2337,
        loadRange: 'SL',
        speedRating: '(110Y)',
        roadHazardId: 440000019604,
        dotNumber: 'HWUWD62P0520'
      }
    ]
  },
  {
    id: 3,
    customerId: 3,
    invoice: 'EY44945',
    invoiceDate: '8/3/20',
    orderDate: '7/31/20',
    tireTotal: 567.96,
    firstName: 'JUSTIN C',
    lastName: 'MCKEEVER',
    address1: '840 SE DEERFIELD LN',
    address2: '',
    city: 'WAUKEE',
    state: 'IA',
    country: 'US',
    zipCode: 50263,
    zipCode2: 8605,
    email: 'JCMCKEEVER@LIVE.COM',
    phone: 5157100176,
    vehicleMake: 'Honda',
    vehicleModel: 'Accord Sedan',
    vehicleYear: 2015,
    invoiceLines: [
      {
        id: 9,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019701,
        dotNumber: 'AP3F0AKX2320'
      },
      {
        id: 10,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019702,
        dotNumber: 'AP3F0AKX2320'
      },
      {
        id: 11,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019703,
        dotNumber: 'AP3F0AKX2320'
      },
      {
        id: 12,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019704,
        dotNumber: 'AP3F0AKX2320'
      }
    ]
  }];


mock.onPost('/api/account/login').reply((config) => {
  const { email, password } = JSON.parse(config.data);

  // if (email !== 'admin@devias.io' || password !== 'admin') {
  //   return [400, { message: 'Please check your email and password' }];
  // }

  const user = _.find(customersDB, (customer) => {
    return customer.email.toLowerCase() === email.toLowerCase();
  });

  if (!user) {
    return [400, { message: 'Please check your email and password' }];
  }

  const accessToken = jwt.sign(
    { id: user.id },
    JWT_SECRET,
    { expiresIn: JWT_EXPIRES_IN }
  );

  return [200, { user, accessToken }];
});

mock.onGet('/api/account/me').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);

    // if (id !== db.user.id) {
    //   return [401, { message: 'Invalid authorization token' }];
    // }

    const user = _.find(customersDB, { id });

    return [200, { user }];
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});

mock.onPost('/api/account/profile').reply((request) => {
  const { update } = JSON.parse(request.data);

  _.assign(db.user, update);

  return [200, { user: db.user }];
});

mock.onPost('/api/account/locate').reply((request) => {
  const {
    firstName,
    lastName,
    streetAddress,
    email,
    memberNumber,
    mobilePhone
  } = JSON.parse(request.data);

  const users = _.shuffle((_.filter(customersDB, (customer) => {
    if (customer.email === email) {
      return true;
    }

    const phoneNumber = mobilePhone.match(/\d/g) || [5555555555];
    if (phoneNumber.join('').toString().indexOf(customer.phone.toString()) >= 0 || phoneNumber.join('').toString().indexOf(customer.shipToPhone.toString()) >= 0) {
      return true;
    }

    if (customer.firstName.toLowerCase() === firstName.toLowerCase()
      && customer.lastName.toLowerCase() === lastName.toLowerCase()
      && streetAddress.toLowerCase().indexOf(customer.address1.toLowerCase()) >= 0
      && streetAddress.toLowerCase().indexOf(customer.state.toLowerCase()) >= 0
      && streetAddress.toLowerCase().indexOf(customer.city.toLowerCase()) >= 0
      && streetAddress.toLowerCase().indexOf(customer.zipCode.toString().toLowerCase()) >= 0) {
      return true;
    }

    return false;
  }) || []).concat([{
    id: -1,
    firstName: 'RANDAY',
    lastName: 'MARSH',
    email: 'RANDAY.MARSH@OUTLOOK.COM',
    phone: 2365484816,
    shipToPhone: 2365487965
  }, {
    id: -1,
    firstName: 'BILLY',
    lastName: 'BOBBY',
    email: 'BILLY.BOBBY@GMAIL.COM',
    phone: 3656423654,
    shipToPhone: 8236548749
  }, {
    id: -1,
    firstName: 'SANDY',
    lastName: 'MCNAMAR',
    email: 'SANDY_MCNAMAR@YAHOO.COM',
    phone: 9236548745,
    shipToPhone: 5648636546
  }]));


  return [200, { users }];
});

mock.onGet('/api/account/settings').reply(200, {
  settings: {}
});

mock.onGet('/api/account/subscription').reply(200, {
  subscription: {
    name: 'Freelancer',
    price: '5',
    currency: '$',
    proposalsLeft: 12,
    templatesLeft: 5,
    invitesLeft: 24,
    adsLeft: 10,
    hasAnalytics: true,
    hasEmailAlerts: true
  }
});
