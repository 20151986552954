import React from 'react';
import PropTypes from 'prop-types';

function Logo({ override, ...rest }) {
  return (
    <img
      alt="Logo"
      src={override || '/static/logo.png'}
      {...rest}
    />
  );
}

Logo.propTypes = {
  override: PropTypes.string
};

export default Logo;
