import axios from 'axios';
import notificationsService from '../services/notificationsService';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';
export const UPDATE_NOTIFICATIONS = '@notifications/update-notifications';
export const MARK_NOTIFICATION_AS_READ = '@notifications/notification-read';
export const MARK_NOTIFICATIONS_AS_READ = '@notifications/all-notifications-read';

export function getNotifications() {
  const request = notificationsService.getNotifications();

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_NOTIFICATIONS,
      payload: response.data
    }));
  };
}

export function markAllNotificationsAsRead() {
  const request = notificationsService.markAllNotificationsAsRead();

  return (dispatch) => {
    request.then((response) => dispatch({
      type: MARK_NOTIFICATIONS_AS_READ,
      payload: response.data
    }));
  };
}

export function markNotificationAsRead(notification) {
  const request = notificationsService.markNotificationAsRead(notification);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: MARK_NOTIFICATION_AS_READ,
      payload: response.data
    }));
  };
}

export function updateNotifications(notification) {
  const request = notificationsService.updateNotifications(notification);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_NOTIFICATIONS,
      payload: response.data
    }));
  };
}
