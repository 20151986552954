import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/claims/account/statistics').reply(200, {
  statistics: {
    payout: '4,250',
    claims: '12,500',
    visitors: '230',
    watching: '5'
  }
});

mock.onGet('/api/claims/account/claims').reply(200, {
  claims: [
    {
      id: '5e8dcf105a6732b3ed82cf7a',
      title: 'Ten80 Web Design',
      media: '/static/images/claims/project_3.png',
      reviewer: {
        id: '5e86809283e28b96d2d38537',
        avatar: '/static/images/avatars/avatar_6.png',
        name: 'Katarina Smith'
      },
      type: 'Full-Time',
      location: 'Europe',
      technology: 'Angular JS',
      isLiked: true,
      likes: 57,
      rating: 3,
      subscribers: 5,
      updatedAt: moment()
        .subtract(24, 'minutes')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/claims/account/todos').reply(200, {
  todos: [
    {
      id: '5e8dd0b59fb490099917eb29',
      title: 'Finish the proposal design and contract for the client ASAP',
      deadline: moment()
        .subtract(2, 'days')
        .toDate()
        .getTime(),
      done: false
    },
    {
      id: '5e8dd0ba89c3f86059b41383',
      title: 'Finish the proposal design and contract for the client ASAP',
      deadline: moment()
        .toDate()
        .getTime(),
      done: false
    },
    {
      id: '5e8dd0c028284550358972c7',
      title: 'Upload the files for the client @Adrian Stefan with the fonts, assets exported and all the fancy svgs',
      deadline: moment()
        .add(2, 'days')
        .toDate()
        .getTime(),
      done: false
    },
    {
      id: '5e8dd0c549ceb790f8c41e96',
      title: 'Re-write the card component with hooks and context',
      deadline: moment()
        .add(5, 'days')
        .toDate()
        .getTime(),
      done: false
    },
    {
      id: '5e8dd0cb2fb6bf63b2e7d298',
      title: 'Send proposal for the fintech claim',
      deadline: moment()
        .add(5, 'days')
        .toDate()
        .getTime(),
      done: false
    },
    {
      id: '5e8dd0d2ad70962ca35da534',
      title: 'Create todo app with react hooks just to get a better understanding of the concept',
      deadline: moment()
        .add(7, 'days')
        .toDate()
        .getTime(),
      done: true
    }
  ]
});

mock.onGet('/api/claims/claims').reply(200, {
  claims: [
    {
      id: '5e8dcef8f95685ce21f16f3d',
      claimDate: moment()
        .subtract(4, 'days')
        .toDate(),
      claimNumber: '35695-A',
      media: '/static/images/claims/project_1.png',
      description: `
      I was driving and the I heard a weird sound. 
      I got out and saw the tire was flat. It was not because of a nail. The side had a giant hole in it`,
      reviewer: {
        id: '5e887b7602bdbc4dbb234b27',
        name: 'Anje Keizer',
        avatar: '/static/images/avatars/avatar_5.png'
      },
      type: 'Full-Time',
      location: 'Europe',
      technology: 'Vue JS',
      disposition: 'Unpaid',
      isLiked: true,
      likes: 7,
      rating: 5,
      subscribers: 2,
      updatedAt: moment()
        .subtract(24, 'minutes')
        .toDate()
        .getTime()
    },
    {
      id: '5e8dcf076c50b9d8e756a5a2',
      claimDate: '8/7/2020',
      claimNumber: '39856-C',
      media: '/static/images/claims/project_2.png',
      description: `
      When I woke up this morning I went to go to work and saw that my tire was low on air. 
      After filling it up with air it worked for about a day or two and then was flat again. 
      After that I took it to a specialist who told me that it was the intake valve that was not working correctly.`,
      reviewer: {
        id: '5e887d0b3d090c1b8f162003',
        name: 'Emilee Simchenko',
        avatar: '/static/images/avatars/avatar_9.png'
      },
      type: 'Full-Time',
      location: 'Europe',
      technology: 'Angular',
      disposition: 'Unpaid',
      isLiked: false,
      likes: 12,
      rating: 4.5,
      subscribers: 3,
      updatedAt: moment()
        .subtract(1, 'hour')
        .toDate()
        .getTime()
    },
    {
      id: '5e8dcf105a6732b3ed82cf7a',
      claimNumber: '45896-A',
      claimDate: '8/7/2020',
      media: '/static/images/claims/project_3.png',
      description: `
      The tread on my tires seems way to low. I have only had them 3 days and the tire already is almost bald.
      `,
      reviewer: {
        id: '5e88792be2d4cfb4bf0971d9',
        name: 'Elliott Stone',
        avatar: '/static/images/avatars/avatar_10.png'
      },
      type: 'Full-Time',
      location: 'Europe',
      technology: 'Ember JS',
      disposition: 'In Dispute',
      isLiked: true,
      likes: 18,
      rating: 4.7,
      subscribers: 8,
      updatedAt: moment()
        .subtract(16, 'hour')
        .toDate()
        .getTime()
    },
    {
      id: '5e8dcf1cc7155d0e947dc27f',
      claimDate: '8/7/2020',
      claimNumber: '52314-A',
      media: '/static/images/claims/project_4.png',
      description: `
      There is a nail in my tire and I can't fix it.
      `,
      reviewer: {
        id: '5e8877da9a65442b11551975',
        name: 'Shen Zhi',
        avatar: '/static/images/avatars/avatar_11.png'
      },
      type: 'Full-Time',
      location: 'Europe',
      technology: 'Java Spring',
      disposition: 'Approved',
      isLiked: false,
      likes: 1,
      rating: 2,
      subscribers: 10,
      updatedAt: moment()
        .subtract(3, 'days')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/claims/claims/1').reply(200, {
  claim: {
    reviewer: {
      name: 'Mike Sanders'
    },
    claimNumber: '6364524',
    status: 'In Progress',
    amount: '23.00',
    type: 'Repair',
    preApproved: true,
    purchase: '',
    claimDate: new Date(),
    lossDate: moment()
      .subtract(16, 'hour')
      .toDate()
      .getTime(),
    disablementDescription: 'I was driving when suddenly I heard a loud pop. My tire had blown on the road. That\'s it.',
    checkedFR: false,
    checkedFL: true,
    checkedBR: false,
    checkedBL: false,
    frontLeftTreadDepth: 1.5,
    frontLeftTreadDepthMeasurement: 'in',
    frontLeftClaimAmount: '',
    frontRightTreadDepth: 0,
    frontRightTreadDepthMeasurement: 0,
    frontRightClaimAmount: '',
    backLeftTreadDepth: 0,
    backLeftTreadDepthMeasurement: 0,
    backLeftClaimAmount: '',
    backRightTreadDepth: 0,
    backRightTreadDepthMeasurement: 0,
    backRightClaimAmount: '',
    flTirePolicy: {
      id: 1,
      customerPrice: 362.79,
      tireRackPrice: 351.37,
      tireRackItem: '84YR2SPC5',
      vendorItem: 3564830000,
      quantity: 1,
      sectionWidth: 285,
      aspectRatio: 40,
      diameter: 22,
      construction: 'ZR',
      tireMake: 'Continental',
      tireModel: 'ContiSportContact 5P',
      maxCap: 2094,
      loadRange: 'SL',
      speedRating: '(106Y)',
      roadHazardId: 440000019602,
      dotNumber: 'HWTPD62R2119'
    },
    frTirePolicy: {
      id: 1,
      customerPrice: 362.79,
      tireRackPrice: 351.37,
      tireRackItem: '84YR2SPC5',
      vendorItem: 3564830000,
      quantity: 1,
      sectionWidth: 285,
      aspectRatio: 40,
      diameter: 22,
      construction: 'ZR',
      tireMake: 'Continental',
      tireModel: 'ContiSportContact 5P',
      maxCap: 2094,
      loadRange: 'SL',
      speedRating: '(106Y)',
      roadHazardId: 440000019602,
      dotNumber: 'HWTPD62R2119'
    },
    blTirePolicy: {
      id: 1,
      customerPrice: 362.79,
      tireRackPrice: 351.37,
      tireRackItem: '84YR2SPC5',
      vendorItem: 3564830000,
      quantity: 1,
      sectionWidth: 285,
      aspectRatio: 40,
      diameter: 22,
      construction: 'ZR',
      tireMake: 'Continental',
      tireModel: 'ContiSportContact 5P',
      maxCap: 2094,
      loadRange: 'SL',
      speedRating: '(106Y)',
      roadHazardId: 440000019602,
      dotNumber: 'HWTPD62R2119'
    },
    brTirePolicy: {
      id: 1,
      customerPrice: 362.79,
      tireRackPrice: 351.37,
      tireRackItem: '84YR2SPC5',
      vendorItem: 3564830000,
      quantity: 1,
      sectionWidth: 285,
      aspectRatio: 40,
      diameter: 22,
      construction: 'ZR',
      tireMake: 'Continental',
      tireModel: 'ContiSportContact 5P',
      maxCap: 2094,
      loadRange: 'SL',
      speedRating: '(106Y)',
      roadHazardId: 440000019602,
      dotNumber: 'HWTPD62R2119'
    },
    viewCount: 3,
    disposition: 'Pre-Approved',
    files: [
      {
        id: '5e8dd0721b9e0fab56d7238b',
        name: 'example-project1.jpg',
        url: '/static/images/claims/project_4.png',
        mimeType: 'image/png',
        size: 1024 * 1024 * 3
      },
      {
        id: '5e8dd0784431995a30eb2586',
        name: 'docs.zip',
        url: '#',
        mimeType: 'application/zip',
        size: 1024 * 1024 * 25
      },
      {
        id: '5e8dd07cbb62749296ecee1c',
        name: 'example-project2.jpg',
        url: '/static/images/claims/project_1.png',
        mimeType: 'image/png',
        size: 1024 * 1024 * 2
      }
    ],
    activities: [
      {
        id: '5e8dd0828d628e6f40abdfe8',
        subject: 'Customer',
        type: 'upload_file',
        description: 'has uploaded a new file',
        createdAt: moment()
          .subtract(23, 'minutes')
          .toDate()
          .getTime()
      },
      {
        id: '5e8dd0893a6725f2bb603617',
        subject: 'Claims adjuster',
        type: 'join_team',
        description: 'Requested more information',
        createdAt: moment()
          .subtract(2, 'hours')
          .toDate()
          .getTime()
      },
      {
        id: '5e8dd08f44603e3300b75cf1',
        subject: 'Customer',
        type: 'join_team',
        description: 'requested an update',
        createdAt: moment()
          .subtract(9, 'hours')
          .toDate()
          .getTime()
      },
      {
        id: '5e8dd0960f3f0fe04e64d8f4',
        subject: 'Claims adjuster',
        type: 'price_change',
        description: 'was assigned to the claim',
        createdAt: moment()
          .subtract(2, 'days')
          .toDate()
          .getTime()
      },
      {
        id: '5e8dd09db94421c502c53d13',
        subject: 'Customer',
        type: 'contest_created',
        description: 'filed claim',
        createdAt: moment()
          .subtract(4, 'days')
          .toDate()
          .getTime()
      }
    ],
    deadline: moment()
      .add(7, 'days')
      .toDate()
      .getTime(),
    updatedAt: moment()
      .subtract(23, 'minutes')
      .toDate()
      .getTime()
  }
})
;
