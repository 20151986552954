import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import mock from 'src/utils/mock';
import jwt from 'jsonwebtoken';
import filter from 'lodash/filter';
import each from 'lodash/each';
import find from 'lodash/find';

const JWT_SECRET = 'devias-top-secret-key';

const invoicesDB = [
  {
    id: 1,
    customerId: 1,
    status: 'paid',
    invoice: 'EX44163',
    invoiceDate: '8/3/20',
    orderDate: '7/18/20',
    tireTotal: 639.96,
    firstName: 'Rajiv',
    lastName: 'Amar',
    address1: '10701 SUNRISE CIR',
    address2: '',
    city: 'ARGYLE',
    state: 'TX',
    country: 'US',
    zipCode: 76226,
    zipCode2: 7704,
    email: 'ramar@nationsafedrivers.com',
    phone: 8178258242,
    vehicleMake: 'Toyota',
    vehicleModel: 'Tacoma 2wd Prerunner',
    vehicleYear: 2012,
    invoiceLines: [
      {
        id: 1,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019501,
        dotNumber: 'UTV61M64119'
      },
      {
        id: 2,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019502,
        dotNumber: 'UTV61M64119'
      },
      {
        id: 3,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019503,
        dotNumber: 'UTV61M64119'
      },
      {
        id: 4,
        invoiceId: 1,
        customerPrice: 159.99,
        tireRackPrice: 154.95,
        tireRackItem: '675TR6DA4SOWL',
        vendorItem: 90000032686,
        quantity: 1,
        sectionWidth: 265,
        aspectRatio: 75,
        diameter: 16,
        construction: 'R',
        tireMake: 'Cooper',
        tireModel: 'Discoverer AT3 4S',
        maxCap: 2756,
        loadRange: 'SL',
        speedRating: '116T',
        roadHazardId: 440000019504,
        dotNumber: 'UTV61M64119'
      }
    ]
  },
  {
    id: 2,
    customerId: 2,
    invoice: 'EY44447',
    status: 'paid',
    invoiceDate: '8/3/20',
    orderDate: '7/31/20',
    tireTotal: 1447.04,
    firstName: 'Trevor',
    lastName: 'Moore',
    address1: '12363 AMETHYST CIR',
    address2: '',
    city: 'CARMEL',
    state: 'IN',
    country: 'US',
    zipCode: 46032,
    zipCode2: 9758,
    email: 'trevor.j.moore@gmail.com',
    phone: 3177419012,
    vehicleMake: 'Mercedes-Benz',
    vehicleModel: 'GLE43 AMG Coupe',
    vehicleYear: 2017,
    invoiceLines: [
      {
        id: 5,
        invoiceId: 2,
        customerPrice: 362.79,
        tireRackPrice: 351.37,
        tireRackItem: '84YR2SPC5',
        vendorItem: 3564830000,
        quantity: 1,
        sectionWidth: 285,
        aspectRatio: 40,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2094,
        loadRange: 'SL',
        speedRating: '(106Y)',
        roadHazardId: 440000019601,
        dotNumber: 'HWTPD62R2119'
      },
      {
        id: 6,
        invoiceId: 2,
        customerPrice: 362.79,
        tireRackPrice: 351.37,
        tireRackItem: '84YR2SPC5',
        vendorItem: 3564830000,
        quantity: 1,
        sectionWidth: 285,
        aspectRatio: 40,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2094,
        loadRange: 'SL',
        speedRating: '(106Y)',
        roadHazardId: 440000019602,
        dotNumber: 'HWTPD62R2119'
      },
      {
        id: 7,
        invoiceId: 2,
        customerPrice: 360.73,
        tireRackPrice: 349.38,
        tireRackItem: '235YR2SPC5',
        vendorItem: 3564820000,
        quantity: 1,
        sectionWidth: 325,
        aspectRatio: 35,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2337,
        loadRange: 'SL',
        speedRating: '(110Y)',
        roadHazardId: 440000019603,
        dotNumber: 'HWUWD62P1720'
      },
      {
        id: 8,
        invoiceId: 2,
        customerPrice: 360.73,
        tireRackPrice: 349.38,
        tireRackItem: '235YR2SPC5',
        vendorItem: 3564820000,
        quantity: 1,
        sectionWidth: 325,
        aspectRatio: 35,
        diameter: 22,
        construction: 'ZR',
        tireMake: 'Continental',
        tireModel: 'ContiSportContact 5P',
        maxCap: 2337,
        loadRange: 'SL',
        speedRating: '(110Y)',
        roadHazardId: 440000019604,
        dotNumber: 'HWUWD62P0520'
      }
    ]
  },
  {
    id: 3,
    customerId: 3,
    invoice: 'EY44945',
    invoiceDate: '8/3/20',
    orderDate: '7/31/20',
    status: 'paid',
    tireTotal: 567.96,
    firstName: 'JUSTIN C',
    lastName: 'MCKEEVER',
    address1: '840 SE DEERFIELD LN',
    address2: '',
    city: 'WAUKEE',
    state: 'IA',
    country: 'US',
    zipCode: 50263,
    zipCode2: 8605,
    email: 'JCMCKEEVER@LIVE.COM',
    phone: 5157100176,
    vehicleMake: 'Honda',
    vehicleModel: 'Accord Sedan',
    vehicleYear: 2015,
    invoiceLines: [
      {
        id: 9,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019701,
        dotNumber: 'AP3F0AKX2320'
      },
      {
        id: 10,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019702,
        dotNumber: 'AP3F0AKX2320'
      },
      {
        id: 11,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019703,
        dotNumber: 'AP3F0AKX2320'
      },
      {
        id: 12,
        invoiceId: 3,
        customerPrice: 141.99,
        tireRackPrice: 137.52,
        tireRackItem: '155YR7PSAS4XL',
        vendorItem: 68640,
        quantity: 1,
        sectionWidth: 215,
        aspectRatio: 55,
        diameter: 17,
        construction: 'ZR',
        tireMake: 'Michelin',
        tireModel: 'Pilot Sport All Season 4',
        maxCap: 1653,
        loadRange: 'XL',
        speedRating: '98Y',
        roadHazardId: 440000019704,
        dotNumber: 'AP3F0AKX2320'
      }
    ]
  }];

const policesDB = [
  {
    // id: 1,
    policyId: '440000019501',
    customerId: 1,
    product: 208,
    plan: '1A',
    invoiceId: 1,
    invoiceLineId: 1,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 2,
    policyId: '440000019502',
    customerId: 1,
    product: 208,
    plan: '1A',
    invoiceId: 1,
    invoiceLineId: 2,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 3,
    customerId: 1,
    policyId: '440000019503',
    product: 208,
    plan: '1A',
    invoiceId: 1,
    invoiceLineId: 3,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 4,
    policyId: '440000019504',
    customerId: 1,
    product: 208,
    plan: '1A',
    invoiceId: 1,
    invoiceLineId: 4,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 1,
    policyId: '440000019601',
    customerId: 2,
    product: 208,
    plan: '1A',
    invoiceId: 2,
    invoiceLineId: 5,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 2,
    policyId: '440000019602',
    customerId: 2,
    product: 208,
    plan: '1A',
    invoiceId: 2,
    invoiceLineId: 6,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 3,
    customerId: 2,
    policyId: '440000019603',
    product: 208,
    plan: '1A',
    invoiceId: 2,
    invoiceLineId: 7,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  },
  {
    id: 4,
    policyId: '440000019604',
    customerId: 2,
    product: 208,
    plan: '1A',
    invoiceId: 2,
    invoiceLineId: 8,
    expirationDate: moment().subtract(4, 'days').add(24, 'months').format('L'),
    effectiveDate: moment().subtract(4, 'days').format('L'),
    benefits: [
      {
        type: 'ers',
        value: 'miles',
        name: 'Emergency Roadside Service',
        minimum: 0,
        maximum: 25
      }, {
        type: 'repair',
        value: 'dollars',
        name: 'Tire Repair',
        minimum: 0,
        maximum: 40
      }, {
        type: 'replacement',
        value: 'dollars',
        name: 'Tire Replacement',
        minimum: 0,
        maximum: 599
      }]
  }
];

mock.onGet('/api/management/customers').reply(200, {
  customers: [
    {
      id: '5e887a62195cc5aef7e8ca5d',
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@devias.io',
      avatar: '/static/images/avatars/avatar_2.png',
      spent: '500.00',
      currency: '$',
      orders: 1,
      isProspect: false,
      isReturning: false,
      acceptsMarketing: false,
      updatedAt: moment()
        .subtract(1, 'days')
        .toDate()
        .getTime(),
      location: 'West Virginia, USA'
    },
    {
      id: '5e887ac47eed253091be10cb',
      name: 'Cao Yu',
      email: 'cao.yu@devias.io',
      avatar: '/static/images/avatars/avatar_3.png',
      spent: '300.00',
      currency: '$',
      orders: 3,
      isProspect: false,
      isReturning: true,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      location: 'Bristow'
    },
    {
      id: '5e887b209c28ac3dd97f6db5',
      name: 'Alex Richardson',
      email: 'alex.richardson@devias.io',
      avatar: '/static/images/avatars/avatar_4.png',
      spent: '0.00',
      currency: '$',
      orders: 0,
      isProspect: true,
      isReturning: false,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(1, 'hours')
        .toDate()
        .getTime(),
      location: 'Georgia, USA'
    },
    {
      id: '5e887b7602bdbc4dbb234b27',
      name: 'Anje Keizer',
      email: 'anje.keizer@devias.io',
      avatar: '/static/images/avatars/avatar_5.png',
      spent: '5,600.00',
      currency: '$',
      orders: 6,
      isProspect: false,
      isReturning: false,
      acceptsMarketing: false,
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      location: 'Ohio, USA'
    },
    {
      id: '5e86809283e28b96d2d38537',
      name: 'Katarina Smith',
      email: 'katarina.smith@devias.io',
      avatar: '/static/images/avatars/avatar_6.png',
      spent: '500.00',
      currency: '$',
      orders: 1,
      isProspect: false,
      isReturning: true,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(11, 'hours')
        .toDate()
        .getTime(),
      location: 'Texas, USA'
    },
    {
      id: '5e86805e2bafd54f66cc95c3',
      name: 'Adam Denisov',
      email: 'adam.denisov@devias.io',
      avatar: '/static/images/avatars/avatar_7.png',
      spent: '0.00',
      currency: '$',
      orders: 0,
      isProspect: true,
      isReturning: false,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(3, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      location: 'California, USA'
    },
    {
      id: '5e887a1fbefd7938eea9c981',
      name: 'Miller Edwards',
      email: 'miller.edwards@devias.io',
      avatar: '/static/images/avatars/avatar_8.png',
      spent: '0.00',
      currency: '$',
      orders: 0,
      isProspect: true,
      isReturning: false,
      acceptsMarketing: false,
      updatedAt: moment()
        .subtract(4, 'days')
        .subtract(5, 'hours')
        .toDate()
        .getTime(),
      location: 'California, USA'
    },
    {
      id: '5e887d0b3d090c1b8f162003',
      name: 'Emilee Simchenko',
      email: 'emilee.simchenko@devias.io',
      avatar: '/static/images/avatars/avatar_9.png',
      spent: '100.00',
      currency: '$',
      orders: 4,
      isProspect: false,
      isReturning: false,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(4, 'days')
        .subtract(15, 'hours')
        .toDate()
        .getTime(),
      location: 'Nevada, USA'
    },
    {
      id: '5e88792be2d4cfb4bf0971d9',
      name: 'Elliott Stone',
      email: 'elliott.stone@devias.io',
      avatar: '/static/images/avatars/avatar_10.png',
      spent: '1,000.00',
      currency: '$',
      orders: 2,
      isProspect: false,
      isReturning: true,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(5, 'days')
        .subtract(2, 'hours')
        .toDate()
        .getTime(),
      location: 'Michigan, USA'
    },
    {
      id: '5e8877da9a65442b11551975',
      name: 'Shen Zhi',
      email: 'shen.zhi@devias.io',
      avatar: '/static/images/avatars/avatar_11.png',
      spent: '0.00',
      orders: 0,
      isProspect: true,
      isReturning: false,
      acceptsMarketing: true,
      updatedAt: moment()
        .subtract(6, 'days')
        .subtract(8, 'hours')
        .toDate()
        .getTime(),
      location: 'Utah, USA'
    },
    {
      id: '5e8680e60cba5019c5ca6fda',
      name: 'Merrile Burgett',
      email: 'merrile.burgett@devias.io',
      avatar: '/static/images/avatars/avatar_12.png',
      spent: '200.00',
      currency: '$',
      orders: 7,
      isProspect: false,
      isReturning: true,
      acceptsMarketing: false,
      updatedAt: moment()
        .subtract(9, 'days')
        .subtract(1, 'hours')
        .toDate()
        .getTime(),
      location: 'Utah, USA'
    }
  ]
});

mock.onGet('/api/management/customers/1').reply(200, {
  customer: {
    fullName: 'Ekaterina Tankova',
    email: 'ekaterina@devias.io',
    phone: '+55 748 327 439',
    state: 'Alabama',
    country: 'United States',
    zipCode: '240355',
    address1: 'Street John Wick, no. 7',
    address2: 'House #25',
    creditCard: '4142 **** **** **** ****',
    autoCC: false,
    verified: true,
    currency: '$',
    vat: 19,
    balance: 0
  }
});

mock.onGet('/api/management/customers/1/emails').reply(200, {
  emails: [
    {
      id: uuidv4(),
      description: 'Order confirmation',
      createdAt: moment()
        .subtract(3, 'days')
        .subtract(5, 'hours')
        .subtract(34, 'minutes')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      description: 'Order confirmation',
      createdAt: moment()
        .subtract(4, 'days')
        .subtract(11, 'hours')
        .subtract(49, 'minutes')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/management/customers/1/invoices').reply(200, {
  invoices: [
    {
      id: uuidv4(),
      date: moment()
        .toDate()
        .getTime(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    },
    {
      id: uuidv4(),
      date: moment()
        .toDate()
        .getTime(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    }
  ]
});

mock.onGet('/api/management/customers/1/logs').reply(200, {
  logs: [
    {
      id: uuidv4(),
      status: 200,
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 522,
      error: 'Invalid credit card',
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 200,
      method: 'DELETE',
      route: '/api/products/d65654e/remove',
      desc: 'Cart remove',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(8, 'minutes')
        .subtract(23, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 200,
      method: 'GET',
      route: '/api/products/d65654e/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(20, 'minutes')
        .subtract(54, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(34, 'minutes')
        .subtract(16, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f',
      desc: 'View product',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(54, 'minutes')
        .subtract(30, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 200,
      method: 'GET',
      route: '/api/products',
      desc: 'Get products',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(56, 'minutes')
        .subtract(40, 'seconds')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      status: 200,
      method: 'POST',
      route: '/api/login',
      desc: 'Login',
      IP: '84.234.243.42',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(57, 'minutes')
        .subtract(5, 'seconds')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/management/products').reply(200, {
  products: [
    {
      id: uuidv4(),
      name: 'Charlie Tulip Dress',
      image: '',
      isAvailable: true,
      isShippable: false,
      inventoryType: 'in_stock',
      quantity: 85,
      variants: 2,
      category: 'dress',
      attributes: ['Cotton'],
      price: 23.99,
      currency: '$',
      createdAt: moment()
        .subtract(1, 'days')
        .toDate()
        .getTime(),
      updatedAt: moment()
        .subtract(6, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      name: 'Kate Leopard Dress',
      image: '',
      isAvailable: false,
      isShippable: true,
      inventoryType: 'out_of_stock',
      quantity: 0,
      variants: 1,
      category: 'dress',
      attributes: ['Cotton'],
      price: 95.00,
      currency: '$',
      createdAt: moment()
        .subtract(3, 'days')
        .toDate()
        .getTime(),
      updatedAt: moment()
        .subtract(2, 'days')
        .subtract(8, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      name: 'Layering Bracelets Collection',
      image: '',
      isAvailable: true,
      isShippable: false,
      inventoryType: 'in_stock',
      quantity: 48,
      variants: 5,
      category: 'jewelry',
      attributes: ['Variety of styles'],
      price: 155.00,
      currency: '$',
      createdAt: moment()
        .subtract(6, 'days')
        .toDate()
        .getTime(),
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(2, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: uuidv4(),
      name: 'Flared Sleeve Floral Blouse',
      image: '',
      isAvailable: false,
      isShippable: true,
      inventoryType: 'limited',
      quantity: 5,
      variants: 1,
      category: 'blouse',
      attributes: ['Polyester and Spandex'],
      price: 17.99,
      currency: '$',
      createdAt: moment()
        .subtract(12, 'days')
        .toDate()
        .getTime(),
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    }
  ]
});

mock.onGet('/api/management/orders').reply(200, {
  orders: [
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(10, 'minutes')
        .toDate()
        .getTime(),
      customer: {
        name: 'Ekaterina Tankova'
      },
      payment: {
        ref: 'FAD103',
        method: 'CreditCard',
        total: '500.00',
        currency: '$',
        status: 'pending'
      },
      status: 'inactive'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(32, 'minutes')
        .subtract(23, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Cao Yu'
      },
      payment: {
        ref: 'FAD102',
        method: 'CreditCard',
        total: '500.00',
        currency: '$',
        status: 'pending'
      },
      status: 'inactive'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(36, 'minutes')
        .subtract(51, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Alex Richardson'
      },
      payment: {
        ref: 'FAD101',
        method: 'PayPal',
        total: '500.00',
        currency: '$',
        status: 'completed'
      },
      status: 'active'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(38, 'minutes')
        .subtract(55, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Anje Keizer'
      },
      payment: {
        ref: 'FAD100',
        method: 'CreditCard',
        total: '500.00',
        currency: '$',
        status: 'pending'
      },
      status: 'inactive'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(40, 'minutes')
        .subtract(3, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Clarke Gillebert'
      },
      payment: {
        ref: 'FAD99',
        method: 'PayPal',
        total: '500.00',
        currency: '$',
        status: 'completed'
      },
      status: 'active'
    },
    {
      id: '5e86805e2bafd54f66cc95c3',
      createdAt: moment()
        .subtract(45, 'minutes')
        .subtract(32, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Adam Denisov'
      },
      payment: {
        ref: 'FAD98',
        method: 'PayPal',
        total: '500.00',
        currency: '$',
        status: 'completed'
      },
      status: 'active'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(48, 'minutes')
        .subtract(57, 'seconds').toDate()
        .getTime(),
      customer: {
        id: '5e887a1fbefd7938eea9c981',
        name: 'Miller Edwards',
        avatar: '/static/images/avatars/avatar_8.png'
      },
      payment: {
        ref: 'FAD97',
        method: 'CreditCard',
        total: '500.00',
        currency: '$',
        status: 'pending'
      },
      status: 'inactive'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(49, 'minutes')
        .subtract(4, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Emilee Simchenko'
      },
      payment: {
        ref: 'FAD96',
        method: 'CreditCard',
        total: '500.00',
        currency: '$',
        status: 'completed'
      },
      status: 'active'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(57, 'minutes')
        .subtract(43, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Elliott Stone'
      },
      payment: {
        ref: 'FAD95',
        method: 'PayPal',
        total: '500.00',
        currency: '$',
        status: 'rejected'
      },
      status: 'inactive'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(59, 'minutes')
        .subtract(6, 'seconds').toDate()
        .getTime(),
      customer: {
        name: 'Shen Zhi'
      },
      payment: {
        ref: 'FAD94',
        method: 'CreditCard',
        total: '500.00',
        currency: '$',
        status: 'canceled'
      },
      status: 'inactive'
    },
    {
      id: uuidv4(),
      createdAt: moment()
        .subtract(1, 'hour')
        .subtract(15, 'minutes')
        .subtract(43, 'seconds')
        .toDate()
        .getTime(),
      customer: {
        name: 'Merrile Burgett'
      },
      payment: {
        ref: 'FAD93',
        method: 'PayPal',
        total: '500.00',
        currency: '$',
        status: 'canceled'
      },
      status: 'inactive'
    }
  ]
});

mock.onGet('/api/management/orders/1').reply(200, {
  order: {
    id: uuidv4(),
    ref: 'FAD107',
    promoCode: null,
    value: '55.25',
    currency: '$',
    status: 'canceled',
    customer: {
      name: 'Ekaterina Tankova',
      address: 'Street King William, 42456',
      city: 'Montgomery',
      country: 'United States'
    },
    items: [
      {
        id: uuidv4(),
        name: 'Project Points',
        cuantity: 25,
        billing: 'monthly',
        status: 'completed',
        value: '50.25',
        currency: '$'
      },
      {
        id: uuidv4(),
        name: 'Freelancer Subscription',
        cuantity: 1,
        billing: 'monthly',
        status: 'completed',
        value: '5.00',
        currency: '$'
      }
    ],
    createdAt: moment()
      .toDate()
      .getTime()
  }
});

mock.onGet('/api/management/policies').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);
    let policies = filter(policesDB, { customerId: id }) || [];
    each(policies, (policy, index) => {
      const invoice = find(invoicesDB, { id: policy.invoiceId });
      if (invoice) {
        policies[index].invoiceData = find(invoice.invoiceLines, { id: policy.invoiceLineId });
      }
    });

    return [200, { policies }];
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});

mock.onGet('/api/management/policies/1').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);
    let policy = find(policesDB, (policy) => {
      return policy.customerId === id;
    }) || {};
    policy.invoice = find(invoicesDB, { id: policy.invoiceId });

    return [200, { policy }];
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});

mock.onGet('/api/management/invoices').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);


    return [200, { invoices: filter(invoicesDB, { customerId: id }) || [] }];
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});

mock.onGet('/api/management/invoices/2').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);

    return [200, { invoice: find(invoicesDB, { customerId: id }) || [] }];
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});
